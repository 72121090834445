body {
		@apply flex flex-col min-h-screen bg-offWhite;

		header {
				@apply w-full h-18 bg-transparent absolute top-0 px-5 flex items-center z-40 mx-auto left-0 right-0;
				max-width: 1320px;
				transition: all .2s ease-in-out;

				&.is-active {
						@apply bg-blue-300;
				}

				@screen lg2 {
						@apply h-24 px-8;
				}

				@screen xxl {
						@apply px-0;
				}
		}

		.faq .wrapper{
				@apply mb-0 pb-12;

				@screen lg{
						@apply pb-24;
				}
		}

		main {
				@apply flex-1;
		}

		.wrapper {
				@apply w-full mx-auto max-w-default relative;

				&-xsmall {
						@apply py-5 px-5 z-20 mx-auto;
						max-width: calc(100% - 2.5rem);

						&-no-pad {
								@apply px-0;
						}

						&-full-width {
								max-width: 100%;
						}

						@screen lg {
								@apply max-w-2xl mt-0 p-16;
						}
				}

				&-small {
						@apply px-5 py-8 shadow;

						@screen lg {
								@apply px-38 py-20 z-20;
						}
				}

				&-large {
						@apply py-12 shadow;

						@screen lg {
								@apply pt-20 pb-0 z-20;
						}
				}

				&.no-shadow {
						@apply shadow-none;
				}
		}

		aside {
				@apply mr-16 w-full;

				@screen lg {
						max-width: 243px;
				}
		}

		footer {
				@apply w-full bg-blue-300 py-10 px-5 text-white;

				@screen lg {
						@apply pb-10 pt-20 px-15;
				}
		}
		&.modal--active {
				header {
						@apply z-20;
				}
		}
}

.faq{
		.wrapper-small.wrapper{
				box-shadow: none;
		}
}

.support-page.wrapper-small {
		max-width: 94%;
		margin-top: -55px;

		@screen lg {
				margin-top: 0;
		}

		@screen lg {
				@apply px-14 py-10;
				max-width: 1012px;
		}

		> div:first-child {
				@screen md {
						@apply mr-32;
				}
		}

		box-shadow: none;
}
