﻿[data-modal-content] {
		[data-modal-overlay], [data-modal-inner] {
				@include ie() {
						@apply inset-0;
				}
		}
}
[data-modal-content] {
		[data-modal-inner] {
				transition: transform 0.5s ease, opacity 0.5s ease;
				transform: translateY(15vh);
		}
		&[data-modal-active] {
				@apply opacity-100;
				[data-modal-inner] {
						@apply opacity-100;
						transform: translateY(5vh);
				}
		}
}
