﻿.umbraco-forms-Facit {
		.input-group {
				@apply mb-12;
		}

		.field-validation-error {
				color: #DB1010;
				font-size: 0.875rem;

				span {
						color: #DB1010;
						font-size: 0.875rem;
				}
		}

		.input-group textarea:focus ~ .label-animated,
		.input-group textarea.filled ~ .label-animated {
				padding-top: 0;
				padding-left: 0;
				transform: translateY(-70px);
				font-size: 15px;
		}

		[type="checkbox"]:not(:checked),
		[type="checkbox"]:checked {
				position: absolute;
				left: -9999px;
				transition: all .3s ease;
		}

		[type="checkbox"]:not(:checked) + [type="hidden"] + label,
		[type="checkbox"]:checked + [type="hidden"] + label {
				position: relative;
				padding-left: 1.95em;
				cursor: pointer;
		}

		[type="checkbox"]:not(:checked) + [type="hidden"] + label:before,
		[type="checkbox"]:checked + [type="hidden"] + label:before {
				content: '';
				position: absolute;
				left: 0;
				top: 2px;
				width: 1.25em;
				height: 1.25em;
				border: 2px solid #ddd;
				background: #fff;
				box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
				transition: all .3s ease;
		}
		/* checked mark aspect */
		[type="checkbox"]:not(:checked) + [type="hidden"] + label:after,
		[type="checkbox"]:checked + [type="hidden"] + label:after {
				width: 20px;
				background-repeat: no-repeat;
				height: 20px;
				background-size: 75%;
				background-position: center center;
				background-image: url("../../Media/svg/checkboxIcon.svg");
				background-color: #224C6C;
				content: "";
				position: absolute;
				top: 2px;
				left: 0;
				transition: all 0.3s ease;
		}
		/* checked mark aspect changes */
		[type="checkbox"]:not(:checked) + [type="hidden"] + label:after {
				opacity: 0;
				transform: scale(0);
		}

		[type="checkbox"]:checked + [type="hidden"] + label:after {
				opacity: 1;
				transform: scale(1);
		}
		/* disabled checkbox */
		[type="checkbox"]:disabled:not(:checked) + [type="hidden"] + label:before,
		[type="checkbox"]:disabled:checked + [type="hidden"] + label:before {
				box-shadow: none;
				border-color: #ddd;
				background-color: #ddd;
		}

		[type="checkbox"]:disabled:checked + [type="hidden"] + label:after {
				color: #999;
		}

		[type="checkbox"]:disabled + [type="hidden"] + label {
				color: #aaa;
		}
		/* accessibility */
		[type="checkbox"]:checked:focus + [type="hidden"] + label:before,
		[type="checkbox"]:not(:checked):focus + [type="hidden"] + label:before {
				border: none;
		}

		input {
				+ .validation-message {
						.field-validation-error {
								@apply hidden;
						}
				}

				&.input-validation-error {
						+ .validation-message {
								.field-validation-error {
										@apply inline-block;
										color: #DB1010;
										font-size: 0.875rem;
								}
						}
				}
		}
}
