.slider-controls {
  @apply flex justify-center mt-8;

  > div {
    @apply rounded-full flex items-center justify-center bg-blue-200;
    width: 48px;
    height: 48px;

    &:hover {
      cursor: pointer;
    }

    svg {
        @apply fill-white;
      }

    &[aria-disabled="true"] {
      @apply bg-grey-275;
      cursor: not-allowed;
			opacity: .4;

      svg {
        @apply fill-blueDark;
      }
    }
  }

	.next, .prev, .slider-controls{
			@apply outline-none;
	}

  .prev {
    @apply mr-2;

    svg {
      transform: rotate(180deg);
    }
  }
}


