.person {
		@apply flex items-center border border-grey-400 p-4 w-full mb-6;

		@screen lg {
				@apply flex-col pt-8 pb-6 justify-start;
				width: calc(33.333% - (1.25rem));
		}

		&:last-of-type {
				@apply mb-0;

				@screen lg {
						@apply mb-6 mr-0;
				}
		}

		div {
				.image-overlay {
						@apply rounded-full mr-5 overflow-hidden;
						width: 64px;
						height: 64px;

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								display: block !important;

						}

						@screen lg {
								@apply mb-6 mr-0;
								width: 120px;
								height: 120px;
						}

						img{
								width: 100%;
						}
				}

				.name {
						@apply text-base font-semibold text-blue-300;

						@screen lg {
								@apply text-lg;
						}
				}

				.title {
						@apply text-sm2 text-blue-300 inline-block mb-2;

						@screen lg {
								@apply text-lg;
						}
				}

				a {
						@apply text-sm2 text-blue-200 font-semibold;
						transition: color .2s ease-in-out;

						&:hover {
								@apply text-blue-300;
						}

						@screen lg {
								@apply text-base;
						}
				}

				&:last-of-type {
						@apply flex flex-col justify-center;

						@screen lg {
								@apply items-center;
						}
				}
		}
}

.wrapper .persons-grid.persons-grid {
		@apply ml-0 mr-0 mt-0;
}
