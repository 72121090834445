﻿.field-validation-error {
		@apply text-error-default text-sm;

		~ .validation-error-icon {
				@apply inline-block opacity-100;
		}
}

.validation-error-icon {
		@apply hidden opacity-0;
		transition:opacity .2s ease-in-out;
}

.validation-message {
		@apply mt-2;
}
.input-validation-error {
		+ label {
				.validation-error-icon {
						@apply block opacity-100;
				}
		}
}
