input {
		&[type="search"] {
				@apply w-full h-14 pl-13;
				-webkit-appearance: none;
		}
}

//Checkboxes

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  transition: all .3s ease;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #ddd;
    background: #fff;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    transition: all .3s ease;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
		width: 20px;
		background-repeat: no-repeat;
		height: 20px;
		background-size: 75%;
		background-position: center center;
		background-image: url("../../Media/svg/checkboxIcon.svg");
		background-color: #224C6C;
		content: "";
		position: absolute;
		top: 2px;
		left: 0;
		transition: all 0.3s ease;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #ddd;
    background-color: #ddd;
}

[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}

[type="checkbox"]:disabled + label {
    color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    border:none;
}

/* hover style just for information */
label:hover:before {
    border: 2px solid #224C6C !important;
}

/* Select */

select {
		@apply w-full border-b border-grey-290 px-0 py-4 text-base text-blue-300 rounded-none font-body;
		appearance: none;
		background: url(./gfx/down-arrow.svg);
		background-repeat: no-repeat;
		background-position: center right .75rem;
		background-size: 12px 12px;

		&:focus {
				outline: none;
		}
}
