﻿table {
		@apply mt-6 mb-12 block overflow-auto;
		width: 100% !important;

		tbody {
				tr {
						@apply p-4;

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								display: block;
						}

						@media screen and (max-width: 768px) {
								height: auto !important;
						}

						@screen md {
								@apply p-0 items-center;
						}

						&:nth-child(odd) {
								@apply bg-blue-100;
						}

						&:last-of-type {
								box-shadow: 0px 6px 8px rgba(34, 76, 108, 0.05);
						}

						td {
								@apply px-4 py-2;

								@media screen and (max-width: 768px) {
										width: auto !important;
										height: auto !important;
								}

								@screen md {
										@apply px-2 py-3;
										height: auto !important;

										&:first-of-type {
												@apply pl-4;
										}

										&:last-of-type {
												@apply pr-4 pb-3;
										}
								}

								p {
										font-size: 1.125rem !important;
								}
						}
				}
		}
}
