﻿/* purgecss start ignore */
.fslightbox-toolbar {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		display: flex;
		background: rgba(35, 35, 35, 0.65);
}

.fslightbox-toolbar-button {
		height: 100%;
		width: 45px;
		cursor: pointer;
}

.fslightbox-flex-centered {
		display: flex;
		align-items: center;
		svg {
				margin: 0 auto;
		}
}

.lds-ring {
		display: block;
		margin: auto;
		position: relative;
		width: 67px;
		height: 67px;
}

.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 54px;
		height: 54px;
		margin: 6px;
		border: 5px solid #999;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #999 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
}

@keyframes lds-ring {
		0% {
				transform: rotate(0deg);
		}

		100% {
				transform: rotate(360deg);
		}
}

.fslightbox-source-holder {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
}

.fslightbox-source {
		display: block;
		margin: auto;
		backface-visibility: hidden;
		transform: translateZ(0);
}

.fslightbox-transform-transition {
		transition: transform .3s;
}

.fslightbox-invalid-file-wrapper {
		font-size: 22px;
		color: #eaebeb;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
}

.fslightbox-fade-in {
		opacity: 1 !important;
}

.fslightbox-fade-in {
		animation: fslightbox-fade-in 0.25s cubic-bezier(0, 0, 0.7, 1);
}

@keyframes fslightbox-fade-in {
		from {
				opacity: .65;
		}

		to {
				opacity: 1;
		}
}

.fslightbox-fade-out {
		animation: fslightbox-fade-out .25s ease;
}

@keyframes fslightbox-fade-out {
		from {
				opacity: .35;
		}

		to {
				opacity: 0;
		}
}

.fslightbox-fade-in-complete {
		animation: fslightbox-fade-in-complete 0.25s cubic-bezier(0, 0, 0.7, 1) forwards;
}

@keyframes fslightbox-fade-in-complete {
		from {
				opacity: .3;
		}

		to {
				opacity: 1;
		}
}

.fslightbox-fade-out-complete {
		animation: fslightbox-fade-out-complete .25s ease !important;
}

@keyframes fslightbox-fade-out-complete {
		from {
				opacity: 1;
		}

		to {
				opacity: 0;
		}
}

.fslightbox-container {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: #1e1e1e;
		background: linear-gradient(rgba(30, 30, 30, 0.9), black 1810%);
		font-family: "Montserrat", sans-serif;
		z-index: 9999999;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
}

.fslightbox-open {
		overflow: hidden;
		height: 100%;
		opacity:1;
}

.fslightbox-scrollbarfix {
		padding-right: 17px;
}

.fslightbox-nav {
		height: 45px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
}

.fslightbox-slide-number-container {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: .82rem;
		color: #c1c2c2;
		z-index: 1;
		max-width: 46px;
}

.fslightbox-slide-number-container .fslightbox-slide-slide-number {
		padding: 0 2px;
}

.fslightbox-slide-number-container .fslightbox-slash {
		padding-top: .15rem;
		font-size: 12px;
}

.fslightbox-svg-icon path {
		fill: #ddd;
}

.fslightbox-svg-icon circle {
		stroke: #4691f6;
		stroke-width: 1;
}

.fslightbox-slide-btn-container {
		display: flex;
		align-items: center;
		padding: 30px 30px 30px 6px;
		position: absolute;
		top: 50%;
		cursor: pointer;
		z-index: 1;
		transform: translateY(-50%);
}

.fslightbox-slide-btn-container:hover .fslightbox-svg-icon path {
		fill: #f1f1f1;
}

.fslightbox-slide-btn {
		padding: 7px;
		font-size: 26px;
		background: rgba(35, 35, 35, 0.65);
}

@media (min-width: 476px) {
		.fslightbox-slide-btn {
				padding: 8px;
		}
}

.fslightbox-slide-btn-left-container {
		left: 0;
}

@media (max-width: 475.99px) {
		.fslightbox-slide-btn-left-container {
				padding-left: 3px;
		}
}

.fslightbox-slide-btn-right-container {
		right: 0;
		padding-left: 30px;
		padding-right: 3px;
}

@media (min-width: 476px) {
		.fslightbox-slide-btn-right-container {
				padding-right: 6px;
		}
}

.button-style {
		display: flex;
		align-items: center;
		justify-content: center;
}

.fslightbox-holder-wrapper {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
}

.fslightbox-media-holder {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
}

.fslightbox-invisible-hover {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
}

.fslightbox-cursor-grabbing {
		cursor: grabbing;
}

.button-style:hover .fslightbox-svg-icon path {
		fill: #fff;
}

.fslightbox-fade-in-window {
		animation: fade-in .5s forwards;
}

.fslightbox-container-fadeout {
		opacity: 0;
		transition: opacity .25s ease;
}

/* purgecss end ignore */
