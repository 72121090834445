.testimonials {
		@apply text-center mx-auto;

		.testimonial {
				@apply w-full px-3;

				@screen lg {
						@apply px-24;
				}

				> .relative {
						height: 4.5rem;
						@apply overflow-hidden;

						picture {
								@apply flex items-center justify-center rounded-full overflow-hidden;
								height: 4.5rem;
								width: 4.5rem;
						}

						img {
								@apply w-auto;
								max-height: none;
								max-width: none;
								height: 4.5rem !important;
						} 
				}
		}
}

.testimonial-single {
    @apply w-full px-3 text-center;

    @screen lg {
        //@apply px-42;
    }
}

.news-wrapper blockquote {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				max-width: 700px;
		}
}


