.side-nav{
    @apply flex flex-wrap mt-6;

    li{
        @apply  flex items-center justify-center mb-4 w-1/2;

        @screen lg{
            @apply w-full justify-start min-h-full mx-0 mb-0;
        }

        a{
            @apply px-4 py-8 flex flex-col bg-grey-300 w-full font-semibold text-lg items-center;
            transition: all .2s ease-in-out;

            @screen lg{
                @apply w-full flex-row justify-start h-14 bg-transparent;
            }

            &:hover{
                @apply bg-grey-300;
            }

            span{
                @apply mb-5;
                @screen lg{
                    @apply mb-0 mr-5;
                }
            }
        }
    }
}
