.top-banner {
		@apply relative;

		@screen lg {
				margin-bottom: -80px;
		}

		&-large {
				min-height: 279px;
		}

		&-overlay {
				@apply h-full w-full absolute top-0 left-0;
				background: linear-gradient(180deg, rgba(34,76,108,0) 0%, rgba(34,76,108,1) 100%);
		}

		&-news {
				@apply bg-blue-300;

				.top-banner-content {
						@apply px-0 pt-26;

						@screen lg {
								@apply px-8 pt-40;
						}

						@screen xl {
								@apply px-0;
						}

						h1 {
								@screen lg {
										@apply text-7xl mb-6;
										line-height: 88px;
								}
						}
				}
		}

		&-content {
				@apply px-5 flex flex-col items-center justify-center text-white pb-8 pt-26 text-white;

				@screen sm {
						@apply py-40;
				}

				@screen lg {
						@apply py-52;
				}

				&-1 {
						@apply pt-26;

						@screen lg {
								@apply pt-52;
						}
				}

				&-2 {
						@apply pt-26 pb-8;

						@screen lg {
								@apply py-50;
						}
				}

				&-mobile-z {
						@media screen and (max-width: 1024px) {
								margin-bottom: -4rem;
						}
				}

				h1 {
						@screen lg {
								line-height: 97px;
								max-width: 58rem;
						}
				}
		}
		//IE ONLY
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				overflow: hidden;

				img {
						width: auto;
						height: auto;
						min-width: 100%;
						min-height: 100%;
						max-width: none;
				}
		}
}

.top-banner-news {
		.icon-pulse {
				@apply ml-auto mt-auto mr-auto mb-auto absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
		}
}

.savings-content .top-banner-content {
		h1 {

				@screen lg {
						line-height: 67px;
				}
		}
}

.top-banner--large .top-banner-content {
		padding-top: 10.5rem;
		padding-bottom: 6rem;

		@screen lg{
				padding-bottom: 13rem;
				padding-top: 16rem;
		}

		.underlined span {
				top: 103%;

				@screen lg {
						top: 94%;
				}
		}
}

//Job positions topbanner

.order-headline {
		.top-banner{
				 span{
						order: 2;
				}

				 h1{
						 @apply mb-6;
						order: 1;
				}
		}
}

.support-page--banner {
		.top-banner-content{
				@apply pb-22;

				@screen lg{
						@apply py-52;
				}
		}
}
