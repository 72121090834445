.footer-nav {

		div {
				@screen lg {
						@apply mr-10;
				}

				@screen xl {
						@apply mr-38;
				}
		}

		div {
				&:last-child {

						@apply border-b border-blue-border-transparent;

						@screen lg {
								@apply border-none;
						}
				}
		}

		ul {
				@media screen and (max-width: 1023px) {
						@apply h-0 invisible opacity-0;
						transition: all 0.2s ease-in-out;
						li {
								@apply hidden;
						}
						&.is-active {
								@apply opacity-100 visible h-auto;
								li {
										@apply block;
								}
						}
				}

				li {
						@apply mb-6 text-special;
						line-height: 1.1875rem;
				}
		}

		.footer-nav-toggler {
				@apply text-special mb-6 inline-block flex w-full border-t border-blue-border-transparent py-5 mb-0 items-center cursor-pointer font-semibold;
				line-height: 1.1875rem;

				@media screen and (max-width: 900px) {
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								padding-bottom: 0;
						}
				}


				@screen lg {
						@apply font-semibold pt-0 border-none;
				}

				.nav-arrow {
						@apply ml-auto;
						padding-bottom: 5px;

						@screen lg {
								@apply hidden;
						}

						svg {
								@apply fill-grey;
						}
				}
		}
}
span.twitter {
		@apply flex w-auto rounded-full;
		border-width: 1px;
		border-color: rgba(255, 255, 255, 0.2);
		padding: 9px;
}

.phone-icon svg {
		transform: scale(.85);
}

.social-icons a.some-margin, .secondary-links{
		@apply mr-3;

		&:last-of-type {
				@apply mr-0;
		}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {	

		ul.is-active {
				padding-top: 2rem !important;
		}
}
