.navigation {
		@apply hidden items-center ml-17;

		@screen lg2 {
				@apply flex;
		}

		.nav-list {
				@apply flex text-white w-full h-full;

				li {
						&[data-toggle-nav] {

								span.nav-arrow {
										@apply flex items-center ml-2 pr-1 relative;
										top: -5px;
										transition: all .2s ease;

										svg {
												@apply fill-current;
										}
								}
						}

						&:first-of-type {
								a {
										@apply pl-0;
								}
						}

						&:last-of-type {
								a {
										@apply pr-0;
								}
						}

						&.toggle_active {
								.nav-arrow {
										transform: rotate(180deg);
								}
						}
				}

				a {
						@apply px-2 font-semibold text-smd;

						@screen xl {
								@apply px-5 text-lg;
						}
				}

				.navigation-dropdown {
						@apply bg-white text-blue-300 items-center hidden px-0 py-9 w-full absolute z-50 opacity-0 invisible h-0;
						transition: all 0.3s ease-in-out;

						&.is-active {
								@apply flex flex-col absolute top-10 opacity-100 visible h-auto items-start;
								top: 100%;
								left: 0;
								min-width: 340px;
								box-shadow: 0px 8px 24px rgba(34, 76, 108, 0.1);

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										left: 2rem;
								}

								li {
										@apply h-12;
								}

								li {
										&:first-of-type {
												a {
														@apply px-4;
												}
										}

										&:last-of-type {
												a {
														@apply px-4;
												}
										}
								}

								a {
										@apply px-4 py-2;
								}
						}

						> ul {
								&.subnav {
										@apply relative flex flex-col opacity-100 left-0;
								}
						}
				}
		}
}
