.banners {
		@apply px-5;

		@screen lg {
				@apply px-0
		}

		.banner {
				@apply mb-8 flex flex-col;

				&:nth-child(even) {
						.banner-text {
								@apply order-2;
						}

						.banner-img {
								@apply order-1;
						}
				}

				@screen lg {
						@apply mb-16 flex-row;
				}

				&:last-of-type {
						@apply mb-0;
				}

				> div {
						@apply w-full flex;

						@screen lg {
								@apply w-1/2;
						}
				}

				.banner-text {
						@apply flex flex-col items-start justify-center px-0 text-blue-300 text-base;

						@screen lg {
								@apply px-20 text-xl order-1;
						}

						a {
								@apply text-base;
						}
				}

				.banner-img {
						@apply mb-6;

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								display: block;
								flex-shrink: 0;
								flex-grow: 0;
						}

						@screen lg {
								@apply mb-0 order-2;
						}

						img {
								@apply w-full h-auto;

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										display: block;
								}
						}
				}
		}
}
