.search {
		@apply relative;

		input {
				@apply pr-4;				
		}

		span {
				@apply absolute flex items-center h-full;
				left: 1rem;
		}
}
