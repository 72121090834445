*{
    box-sizing: border-box;
}

body {
    @apply text-base font-body;
}


h1 {
    @apply text-4xl font-semibold;
    line-height: normal;

    @screen lg{
        @apply text-5xl;
        line-height: 58px;
    }
}

h2 {
    @apply text-3.5xl text-blue-300 font-semibold mb-2 inline-block;
    line-height: 42px;

    &.headline {
        @apply text-2xl pb-3 w-full border-b border-grey-400 mb-8;
        line-height: 31px;
    }

    @screen lg {
        @apply text-5.5xl mb-8;
        line-height: 67px;
    }
}

h3,
.h3 {
		@apply text-2xl text-blue-300 font-semibold mb-2;

		@screen lg {
				@apply text-5.5xl mb-4;
				line-height: 67px;

				&-small {
						@apply text-5xl;
				}
		}
}

h4{
    @apply text-2xl font-semibold text-blue-300 mb-2;

    @screen lg{
        @apply text-3.5xl mb-4;
    }
}

h5{
    @apply text-lg font-semibold text-blue-300 mt-2;

    @screen lg{
        @apply text-xl;
    }
}

//IE
h1, h2, h3, h4, h5, h6, p, span, a {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				/* IE10+ CSS styles go here */
				max-width: 100%;
		}
}


a {
    &.underlined {
        @apply relative;

        span {
            @apply absolute top-10 left-0 w-full overflow-hidden;
            transition: all .3s ease-in-out;

            svg {
                @apply w-full h-auto;
                min-width: 80px;

            }

            &.filler {
                width: 100%;
                max-width: 0;
                position: absolute;
                top: 0;
                left: 0;
                transition: all .2s ease-in-out;

                svg {
                    path {
                        @apply fill-blueDark;
                    }
                }
            }
        }

        &:hover {
            span {
                &.filler {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        &-hover {
            span {
                @apply w-0;
            }

            &:hover {
                span {
                    @apply w-full
                }
            }
        }

    }
}

.underlined {
		@apply relative inline-block;


		span {
				@apply absolute left-0 w-full overflow-hidden;
				transition: width .3s ease-in-out;
				top: 94%;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						/* IE10+ CSS styles go here */
						height: 14px;
				}

				@screen lg {
						@apply inline-block;
				}

				svg {
						@apply w-full;
						height: 10px;
						min-width: 80px;
				}
		}

		&-small {
				span {
						top: 90%;
						width: 90%;
				}
		}
}

@keyframes fillUp {

    0% {
        opacity: 0;
        min-width: 0;
        width: 0;
    }

    75% {
        opacity: 100;
    }

    100% {
        min-width: 80px;
        width: 100%;
    }

}

@keyframes fillDown {

    0% {
        opacity: 100;
        width: 100%;
        min-width: 80px;
    }

    10% {
        opacity: 0;
    }

    100% {
        min-width: 0;
        width: 0;
    }

}


blockquote {
    @apply text-blue-300 text-xl;

    @screen md {
        @apply text-2xl;
    }

    .footer {
        @apply inline-block w-full mt-6 flex flex-col;
    }
}

.grid-text {
		p {
				@apply mb-4 text-xl text-blue-300;
		}

		h4 {
				@apply mt-0;
		}
}

.resize-svg {
		transform: scale(0.7);
		transition: all 0.2s ease;

		@screen md {
				transform: scale(1);
		}
}
