﻿.CookieDeclarationHeader{
		@apply hidden;
}

.CookieDeclarationIntro {
		h2 {
				@apply text-32 mb-0;
		}

		p {
				@apply max-w-full;
		}
}

.coitable__declaration{
		@apply mt-12;
}

textpage-Wrapper {
		h1, h2, h3, h4, h5, h6, p, span, ul {
				@apply max-w-textarea m-auto;
		}

		h2 {

				@screen lg {
						line-height: 67px;
				}
		}

		p {
				@apply text-xl text-blue-300;
		}

		span {
				@apply text-xl text-blue-300;
		}

		h4 {
				@apply pb-4;

				* {
						@apply text-4xl;
				}
		}

		ul {
				@apply pl-4 text-base text-blue-300 my-4;

				@screen lg {
						@apply pl-14 my-6;
				}

				li {
						@apply mb-4 text-xl;

						&:before {
								@apply mr-3;
								content: "•"
						}

						&:last-child {
								@apply mb-0;
						}

						ul {
								@apply my-2;

								li {
										@apply mb-2;
								}
						}
				}
		}

		a:not(.btn) {
				@apply font-semibold border-b border-blue-200 text-blue-200;
				transition: all .3s ease-in;

				&:hover {
						@apply text-blue-300 border-blue-300;
				}
		}

		div.w-full.px-4 {
				@apply flex items-center flex-col mb-4;

				@screen sm {
						@apply mb-0;
						max-height: initial;
				}

				img {
						@apply h-full w-auto min-w-full block;
						max-width: inherit;
				}
		}

		.btn {
				span {
						@apply mr-0;
				}
		}
}


.textarea-quote {
		@apply pt-14 flex flex-col;

		&:before {
				@apply w-full max-w-textarea bg-grey-300 m-auto mb-14;
				content: "";
				height: 1px;
		}
}
