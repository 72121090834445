.savings-content {
		background: #224C6C;

		.top-banner-content {
				@apply p-0;
				margin-bottom: -5rem;

				h1 {
						margin-bottom: 8rem;
						margin-top: 6rem;
						max-width: 704px;

						@screen lg {
								margin-top: 10rem;
						}
				}
		}

		form {
				&:last-of-type {
						.form-section {
								@apply mb-0;

								> .line {
										@apply hidden;
								}
						}
				}
		}
}
.form-section {
		@apply mb-10;

		& > .line {
				width: inherit;
				height: inherit;
		}

		.form-inputs {
				@apply w-full;

				@screen lg {
						padding-left: 44px;
				}
		}

		&.inactive {
				.form-header {
						@apply mb-0;
				}
		}

		.form-header {

				@apply text-blue-300 text-xl;

				@screen md {
						@apply items-center;
				}

				&.valid {
						@apply mb-0;
				}

				div.rounded-box {
						@apply w-7 h-7 mr-3 flex items-center justify-center flex-shrink-0 rounded-full text-blue-300 bg-grey-300 font-semibold relative;
						font-size: 14px;
						transition: all .2s ease-in-out;

						@screen md {
								@apply w-10 h-10;
						}

						& .line {
								@apply hidden border-grey-400 top-10 z-0 absolute;
								border-width: 1px;
								width: 1px;
								left: 50%;
								margin-left: -1px;
								height: 50px;
								transition: all .2s ease-in-out;

								@screen lg {
										@apply block;
								}
						}

						&.active {
								@apply bg-blue-300 text-white;
						}

						&.valid {
								@apply bg-blue-300;

								span.number {
										@apply opacity-0 invisible w-0;
								}

								svg {
										@apply opacity-100 visible w-auto;
								}
						}

						span.number {
								transition: opacity .3s ease-in-out;
						}

						svg {
								@apply opacity-0 w-0;
								transition: opacity .3s ease-in-out;
						}
				}
		}

		&:last-of-type {
				span.rounded-box {
						.line {
								@apply hidden;
						}
				}
		}
}



.input-group {
		@apply relative h-15 flex items-end bg-grey-200 mb-8;

		&-multiple {
				@apply flex;

				> div {
						&:first-of-type {
								@apply mr-4;
								max-width: 90px;

								@screen lg {
										max-width: 154px;
								}
						}

						@apply w-full;
				}

				&-large {
						@apply flex justify-between;
						div {
								@apply mr-4 flex-1 max-w-full;
								
								&:last-of-type{
										@apply mr-0;
								}
						}
				}
		}

		&-radio {
				@apply flex flex-col items-start;

				> div {
						@apply flex w-full;

						> div {
								@apply mr-5 flex items-center relative;
						}

						input {
								@apply w-6 h-6 mr-3 border-2 border-grey-275 rounded-full;
								-webkit-appearance: none;
								transition: all .3s ease;

								&:checked {
										@apply border-blue-300;

										~ .input-radio-circle {
												@apply opacity-100;
												left: 6px;
										}
										@include safari() {
												background: #fff !important;
										}
								}
						}
				}

				label {
						@apply flex;
				}

				span {
						&.input-radio-circle {
								@apply opacity-0 absolute bg-blue-300 rounded-full z-10 w-3 h-3;
								left: 6px;
								top: 6px;
								transition: all 0.2s ease-in-out;
						}

						&.label {
								@apply mb-4;
						}
				}
		}

		
}

input, textarea {
		@apply h-10 border-b-2 border-grey-290 w-full rounded-none;

		@screen md {
				@apply border-grey-400;
		}

		&:focus {
				outline: none;

				~ .label-animated {
						@apply pt-0 pl-0;
						transform: translateY(-30px);
						font-size: 15px;
				}
		}

		&.filled {
				~ .label-animated {
						@apply pt-0 pl-0;
						transform: translateY(-30px);
						font-size: 15px;
				}
		}
}

label,
.label {
		@apply font-semibold text-base text-blue-300;

		&.label-animated {
				bottom: 0.5rem;
				left: 0;
				pointer-events: none;
				transition: all 0.2s ease-in-out;
		}
}

textarea {
		@apply h-32 bg-offWhite p-4;

		~ .label-animated {
				@apply p-4 pl-4;
				top: 0;
		}
}

//Line styling
.form-section {
    @apply relative;

    .line-2 {
        @apply h-auto min-h-full hidden absolute flex-col;
        width: 2px;
        background: #E5E5E5;
        top: 40px;
        padding-bottom: 20px;
        left: 1.2rem;

        @screen lg {
            @apply flex;
        }
    }

    &:nth-last-child(2) .line-2 {
        top: 4rem;
    }
}

