.mobile-navigation {
		@apply opacity-0 h-0 invisible z-0;
		transition: all .2s ease-in-out;

		&.is-active {
				@apply block opacity-100 visible h-auto z-50;
		}

		nav {
				@apply bg-blue-300 absolute w-full px-5 pb-8 left-0 z-50 pt-10 text-white top-[70px];
				min-height: calc(100vh - 4.5rem);
				
				@screen lg2 {
						@apply hidden;
				}

				ul.mobile-nav-list {
						> li {
								@apply mb-5;

								&:last-of-type {
										@apply mb-0;
								}

								&[data-toggle-nav] {
										@apply relative;

										a {
												@apply flex items-center;
										}
								}

								> a {
										@apply text-3xl inline-block w-full font-semibold leading-mobilenav;
								}
						}
				}
		}
}

header .flex .btn.login-btn-mobile{
		@apply w-auto ml-auto px-6;
		@screen md {
				@apply ml-4;
		}
}

.mobile-navigation-dropdown {
		@apply relative h-0 opacity-0 invisible;
		transition: all .2s ease-in-out;

		&.is-active {
				@apply opacity-100 visible h-auto pt-6;
		}

		li {
				@apply mb-3;

				a {
						@apply text-xl font-sans inline-block w-full flex items-center;
				}
		}
}

header.is-active .nav-arrow {
		margin-bottom: .3rem;
}

.nav-arrow {
		@apply inline-block ml-4 self-end;
		

		&.open {
				svg {
						transform: rotate(-90deg);
				}
		}

		svg {
				@apply fill-current;
				width: 16px;
				height: 16px;
				transform: rotate(90deg);
				transition: transform .15s ease-in-out;
		}
}

.hamburger-box {
		@apply flex items-center justify-center flex-col;
		width: 2rem;
		height: 2rem;

		&:focus {
				@apply outline-none;
		}

		&:first-child {
				&:before,
				&:after,
				> .line {
						width: 20px;
						height: 2px;
						background-color: white;
						transition: all 0.25s ease-out;
				}

				.hamburger-inner {
						opacity: 1;
						visibility: visible;
						width: 20px;
						height: 2px;
						background: #fff;
				}

				&:before,
				&:after {
						display: block;
						content: "";
						margin: 4px 0;
						position: static;
				}

				&:before {
						transform: translateY(-50%);
				}

				&:after {
						transform: translateY(50%);
				}
		}
}

.hamburger.is-active {
		.hamburger-box {
				.hamburger-inner {
						opacity: 0;
						visibility: hidden;
				}

				&:before,
				&:after {
						margin: -2px 0;
				}

				&:before {
						transform: rotate(45deg);
				}

				&:after {
						transform: rotate(-45deg);
				}
		}
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10+ CSS styles go here */

		.mobile-login {
				margin-top: 100% !important;
		}
}
