$hamburger-layer-width : 20px !default;
$hamburger-layer-height : 2px !default;
$hamburger-padding-x : 0 !default;
$hamburger-padding-y : 0 !default;
$hamburger-layer-spacing : 4px !default;
$hamburger-layer-color : #fff !default;

/*@import './../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';*/

button {
  &:focus {
    outline: none;
  }
}

.top-banner-content .btn {

		@screen md {
				max-width: none;
				width: auto;
				min-width: 150px;
		}
}

.btn {
		@apply relative border border-transparent rounded-btn px-6 h-14 inline-flex items-center text-white w-full justify-between;
		box-sizing: border-box;
		@screen md {
				max-width: 250px;
		}

		&-white {
				@apply bg-white text-blue-300 border border-grey-275 text-center px-7 w-auto font-semibold;
				transition: all .2s ease-in-out;

				&:hover {
						@apply border-blue-300 border-2;
				}
		}

		&-blue {
				@apply bg-blue-200;
				transition: all .2s ease-in-out;

				&:hover {
						background-color: darken(#8AB4C9, 5%);

						@apply text-white;

						svg {
								@apply fill-white;
						}
				}

				svg {
						@apply fill-white;
				}
		}

		&-disabled {
				@apply bg-grey-300 text-blue-300;

				&:hover {
						@apply bg-grey-300 text-blue-300;
						cursor: not-allowed;

						svg {
								@apply fill-blueDark;
						}
				}

				svg {
						@apply fill-blueDark;
				}
		}

		&-clean {
				@apply pr-6;
		}

		&-autowidth {
				@apply w-auto;
				&.btn-fullwidth {
						@apply w-full max-w-full;
						@screen md {
								max-width: 250px;
						}
				}
		}

		&-small {
				@apply px-4 h-5 text-xs w-auto;
		}

		span {
				@apply pl-4;
		}
}

header .btn-clean {
		@apply h-12 pl-7 pr-7 font-semibold;
}

.news-banner .btn-small{
		@apply h-6 pl-3 pr-3 font-semibold;
}

header .mobile-nav-btn {
		max-width: 218px;
}
