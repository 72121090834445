.faq {
  > div {
    @apply mb-10;

		details:last-child{
				@apply mb-0;
		}

    @screen lg{
      @apply mb-16;
    }
  }
}

.joblist h2{
		@apply mt-8;

		&:first-of-type{
				@apply mt-0;
		}
}

details {
  @apply mb-3;


  summary {
    @apply pl-6 pr-6 pt-6 pb-5 flex items-center bg-grey-300 text-blue-300 text-base font-semibold;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    @screen lg{
      @apply text-xl;
    }

    &::-webkit-details-marker {
      display: none !important;
    }

    &:before,
    &:after {
      display: none !important;
    }

    &:focus{
        outline:none;
    }

    .icon {
      svg {
        @apply fill-blueDark w-4 h-4;
        transition: all 0.2s ease-in-out;
        transform: rotate(90deg);
      }
    }
  }

  .details-inner{
    @apply bg-grey-300 px-6 pb-6;
    transition: background-color 0.2s ease-in-out;
		a:not(.btn) {
				@apply font-semibold border-b border-blue-200 text-blue-200;
				transition: all .3s ease-in;

				&:hover {
						@apply text-blue-300 border-blue-300;
				}
		}
}

  &[open] {
      summary{
          @apply bg-white;
      }
    .icon {
      svg {
        transform: rotate(-90deg);
      }
    }
    .details-inner{
        @apply bg-white;
        box-shadow: 0px 4px 8px rgba(34, 76, 108, 0.05);
    }
  }

  &.small {
    summary {
      @apply pl-5 py-5;
    }
    .details-inner{
        @apply pl-5 pb-8;
    }
  }
}

.faq-inner{
		div:last-child{
				@apply mb-0;
		}
}
