﻿.subnav {
		@apply hidden opacity-0;
		transition: opacity .2s ease-in-out;
		border-right: 1px solid #E5E5E5;

		&.is-active {
				display: flex;
				flex-direction: column;
				opacity: 1;
				position: absolute;
				left: 100%;
				top: 0;
				height: 100%;

				li {
						transition: background .2s ease-in-out;

						&.is-active {
								background: #eee;
						}
				}
		}
}
