.news-wrapper {
		.news-text {
				> h1, h2, h3, h4, h5, h6, p, span {
						max-width: 700px;
						margin: 0 auto;
				}

				>h2{
						@apply mb-8;
				}
		}

		p {
				color: #224C6C;

				@screen md {
						font-size: 20px;
						line-height: 30px;
				}
		}

		img {
				@apply w-full m-auto;
				height: auto !important;
		}
}

.news-text {
		@apply mx-auto mb-16 flex flex-col items-center;

		h1, h2, h3, h4, h5, h6, p, span {
				max-width: 700px;
		}

		h1, h2 {
				@apply mb-4;

				@screen lg {
						@apply mb-8;
				}
		}

		h2 {
				@apply leading-crowded;
		}

		h4 {
				@apply mt-14 mb-4;
		}

		> div {
				@apply my-6 border-b;

				@screen lg {
						@apply my-8;
				}

				border-color: #E5E5E5;
				padding-bottom: 4.5rem;

				.testimonial {
						@apply p-0;

						@screen lg {
								@apply p-0;
						}
				}

				&:last-child {
						@apply border-b-0 pb-4;
				}
		}

		blockquote .footer {
				@apply ml-auto mr-auto;
		}

		&:after {
				@apply border-b border-grey-400 w-full pt-8;
				content: "";
				max-width: 700px;
				height: 1px;
		}
}

.img{
    &-overflow{
        margin-left:-150px;
        margin-right:-150px;
    }
}

.news-list--more {
		max-width: 1012px;
		
}
