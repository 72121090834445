.card {
		@apply flex items-center w-full mb-6 text-blue-300 overflow-hidden;

		@screen lg {
				@apply flex-col justify-start;
				margin: 0 0.625rem 1rem;
				width: calc(33.333% - (1.25rem));
		}

		.flex-1 {
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						flex: 1 1 auto;
				}
		}

		a {
				div {
						&:last-of-type {
								@apply p-5 bg-grey-300;
						}
				}

				&:hover {
						img {
								transform: scale(1);
						}
				}
		}

		img {
				transform: scale(1.05);
				transition: transform .2s ease-in-out;
				height: 200px;
		}
		/*&:last-of-type {
    @apply mb-0;

    @screen lg {
      @apply mb-6 mr-0;
    }
  }*/
		> a > div {
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						max-height: 200px;
						overflow: hidden;

						img{
								width: 100%;
								height: auto;
								max-height: unset;
								max-width: unset;
						}
				}
		}
}
