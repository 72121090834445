﻿// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
// Please avoid creating unnecessary mixins, like mixins for vendor-prefixes, or
// setting a collection of properties.
//
// Do not:
// @mixin pos-abs() {
//  position:absolute;
//  top:0;
//  bottom:0;
//  right:0;
//  left:0;
// }

/// Targets Edge for specific styling
@mixin safari() {
  @media not all and (min-resolution:.001dpcm) {
    @content;
  }
}

