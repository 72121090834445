﻿@layer components {

		[error-icon] {
				@apply hidden;
		}

		[data-file-delete-file] {
				@apply hidden;
		}

		[data-document-portal] {
				[data-file-upload] {
						transition: border 0.3s ease-out, background 0.3s ease-out;
				}

				[data-file-upload="failed"] {
						[data-file-description-placeholder] {
								@apply hidden;
						}

						[error-icon] {
								@apply block;
						}

						[no-error] {
								@apply hidden;
						}

						.upload-validated {
								@apply hidden;
						}

						[data-file-size] {
								@apply hidden;
						}
				}

				[data-file-upload="validated"] {
						.truncate {
								@apply text-blue-200;
						}

						[files-added] {
								@screen md {
										@apply block;
								}
						}

						[data-file-description-placeholder] {
								@apply hidden;
						}

						[update-file-size] {
								@apply hidden;
						}

						.upload-validated {
								@apply hidden;
						}

						.validate-wrap {
								@apply flex-wrap;

								@screen md {
										@apply flex-row;
								}
						}

						[validated-icon] {
								@apply flex;
						}

						[no-error] {
								@apply hidden;
						}
				}

				[data-file-choose-file] {
						@apply flex justify-center;

						@screen sm {
								@apply justify-start;
						}
				}

				[data-file-upload="validated"], [data-file-upload="failed"] {
						[data-file-title-placeholder] {
								@apply hidden;
						}
				}

				[data-file-select="disabled"] {
						@apply pointer-events-auto;

						[data-file-choose-file] {
								@apply hidden;
						}

						[data-file-delete-file] {
								@apply block bg-blue-200 text-white;

								svg {
										@apply text-white;
								}
						}
				}

				[data-form-section] {
						transition: background 0.3s ease-out;
				}

				.field-validation-error {
						@apply block font-normal;
				}

				.field-validation-valid:empty {
						display: none;
				}

				[data-document-portal-submit="disabled"] {
						@apply opacity-40 cursor-default;
				}
		}

		[data-file-title-placeholder], .truncate {
				@apply text-lg;
		}

		.upload-svg--before {
				&:before {
						content: "test";
				}
		}

		[data-document-portal-wrapper] {
				.form-section.inactive {
						@apply hidden;

						@screen md {
								@apply flex;
						}
				}

				.rounded-box {
						width: 2.5rem !important;
						height: 2.5rem !important;
				}
		}

		[data-document-portal-container="loading"] {
				@apply relative;

				[data-button-arrow] {
						@apply hidden;
				}

				&:after {
						content: "";
						@apply absolute z-20 inset-0 block bg-grey-150;
				}
		}

		[data-document-portal="invalid"] {
				[data-form-section] {
						@apply opacity-40 cursor-default;
				}
		}

		.form-link--section {
				@apply w-full p-8 border border-grey-300 mb-6;
				transition: all .3s ease;

				&:hover {
						@apply bg-white shadow-product border-blue-200;
				}

				&.active-section {
						background: #79A9C1;

						h5, p {
								@apply text-white;
						}

						.svg-icon {
								@apply bg-blue-200;

								svg path {
										fill: white;
								}
						}

						.selected--yes {
								@apply bg-white flex;
								color: #79A9C1;

								img {
										@apply w-5;
								}
						}

						.selected--no {
								@apply hidden;
						}
				}

				.selected--yes {
						@apply hidden;
				}
		}

		.document-portal--container {
				h2 {
						@apply max-w-full break-words;
				}

				.form-link--section {

						@screen lg {
								@apply mr-8 mb-0 w-1/3;
								width: calc(50% - 1rem);

								&:last-of-type {
										@apply mr-0;
								}
						}

						.svg-icon {
								transition: all .3s ease;

								svg path {
										transition: all .3s ease;
								}
						}

						h5, p {
								transition: all .3s ease;
						}

						> a {
								@apply flex flex-col items-center justify-center;

								> p {
										@apply text-center;
								}
						}

						> div {
								@apply flex justify-center;
						}
				}


				.btn-autowidth {
						max-width: none;
				}

				.active-parent .calc-height {
						@apply block;
				}

				.calc-height > .form-section {
						@apply w-full;
						left: 0;
						max-width: 1320px;

						@screen lg {
								@apply w-full;
						}

						> div {
								@apply w-full;
						}
				}

				.form-inputs {
						@apply mx-4 pl-0 max-w-full overflow-hidden break-words;

						@screen md {
								@apply mx-0;
						}

						@screen lg {
								@apply pl-0;
								padding-left: 0 !important;
						}
				}
		}

/*		.document-portal--banner {
				&.opacity-15 {
						img {
								@apply opacity-15;
						}
				}
		}*/

		.document-submit .btn {
				@apply mt-8 mx-auto w-full;

				@screen sm {
						max-width: 250px;
						@apply w-auto;
				}

				@screen md {
						@apply ml-auto mr-0;
				}

				@screen lg {
						@apply mt-14;
				}

				&.show-next {
						[data-button-arrow] {
								@apply pl-10;
						}

						@apply min-w-full;

						@screen sm {
								min-width: 226px;
						}
				}
		}

		.document-portal--container .btn--edit {
				svg {
						fill: #224C6C;
				}
		}

		.document--form form {
				@apply w-full max-w-full;
		}
}
