.icon {

		&-pulse {
				@apply relative z-0 mt-7;

				@screen lg {
						@apply mt-12;
				}

				&:before {
						content: "";
						animation: beat 3.5s infinite;
						position: absolute;
						border-radius: 50%;
						width: 100%;
						height: 100%;
						z-index: -1;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
				}

				@apply bg-white rounded-full w-14 h-14 flex items-center justify-center;

				@screen lg {
						@apply w-18 h-18;
				}

				svg {
						@apply fill-blue;
				}
		}

		&-centered {
				@apply absolute left-0 right-0 m-auto;
				top: 50%;
				transform: translateY(-50%);
		}
}


@keyframes beat {

    0%,
    100% {
        transform: scale(2);
        background: #fff;
        opacity: 0;
    }

    0% {
        transform: scale(1);
        background: #fff;
        opacity: 1;

    }

}
