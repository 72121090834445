.product-overview {
		@apply bg-white flex flex-col relative mb-10 border-b border-grey-400 pb-12;

		@screen lg {
				@apply flex-row pb-0 mb-20;
		}

		> div:first-of-type {
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						@apply inline;

						h2 {
								width: 100%;
						}
				}
		}

		.max-w-product {
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						flex-shrink: 0;
				}
		}

		a.product, button.product {
				@apply inline-flex w-full px-6 py-4 bg-grey-250 items-center text-blue-300 shadow-product border border-grey-300 mb-2;
				transition: all .2s ease-in-out;

				@screen lg {
						@apply mb-3;
				}

				&:last-of-type {
						@apply mb-0;
				}

				span {
						&.product-rent {
								@apply ml-auto flex items-center relative overflow-hidden whitespace-nowrap text-blue-300;

								@screen lg {
										max-width: 58px;
										transition: all .2s ease-in-out;
								}

								> span {
										@apply relative right-0 hidden;

										@screen lg {
												@apply block;
										}
								}
						}
				}

				&-accented {
						@apply bg-blue-200 text-white;

						span {
								&.product-rent {
										@apply text-white;
								}
						}

						@screen lg {
								&:hover {
										@apply text-blue-300;

										span {
												&.product-rent {
														@apply text-blue-300;
												}
										}
								}
						}
				}

				@screen lg {
						&:hover {
								@apply bg-white border-blue-200 shadow-product-hover;

								span {
										&.product-rent {
												max-width: 90px;
												transition: all .4s ease-in-out;
										}
								}
						}
				}
		}
}
